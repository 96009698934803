import React, { useState } from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import {
  useAvailableHeight,
} from '@fingo/lib/hooks';
import RoundedWhiteBox from '@fingo/lib/components/boxes/RoundedWhiteBox';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import { CollectionSummaryCards, CollectionSummaryDataGrid } from '@fingo/lib/components/collection-summary';
import {
  INFORMATION_TOOLTIP_TEXT,
} from '@fingo/lib/constants/collection-summary';
import CollectionLoader from './CollectionLoader';
import useCollectionSummary from '../hooks/useCollectionSummary';
import ClientConfigDialog from '../../../components/dialogs/ClientConfigDialog';
import Debtors from '../pages/Debtors';
import { MINIMAL_HEADERS, MINIMAL_INITIAL_PAGE_SIZE } from '../constants/debtors';
import InvoicesInCollection from '../pages/InvoicesInCollection';

const NewCollectionSummary = () => {
  const [openClientConfig, setOpenClientConfig] = useState(false);
  const [height, refChange] = useAvailableHeight(10);

  const {
    collectionManagersByExpiration,
    collectionManagersByPriority,
    loading,
  } = useCollectionSummary();

  if (loading) return <CollectionLoader />;

  return (
    <>
      <PaperHeader
        viewTitle="Resumen de cobranza"
        informationTooltipText={INFORMATION_TOOLTIP_TEXT}
        mb={4}
        endComponent={(
          <Stack direction="row" spacing={2} marginRight={1}>
            <Button
              variant="contained"
              onClick={() => setOpenClientConfig(true)}
              sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', paddingY: 0, height: 35, justifyContent: { xs: 'center', md: 'initial' } }}
              startIcon={<SettingsIcon />}
              id="client-config-button"
            >
              <Typography sx={{ textAlign: 'center', display: { xs: 'none', md: 'block' } }}>Configuraciones</Typography>
            </Button>
          </Stack>
        )}
      />
      <Grid
        container
        spacing={2}
        sx={{
          borderRadius: 2,
          p: 2,
          bgcolor: 'background.light',
        }}
        columnSpacing={{ xs: 0, md: 1 }}
        ref={refChange}
        height={height}
      >
        <Grid
          item
          lg={12}
          xl={6}
          sx={{ borderRadius: 2 }}
        >
          <Stack direction="column" spacing={2}>
            <CollectionSummaryCards
              collectionManagersByExpiration={collectionManagersByExpiration}
            />
            <InvoicesInCollection />
          </Stack>
        </Grid>
        <Grid
          item
          lg={12}
          xl={6}
          sx={{ borderRadius: 2 }}
        >
          <Stack direction="column" spacing={2}>
            <RoundedWhiteBox>
              Principales deudores
              <Debtors
                includeHeaders={MINIMAL_HEADERS}
                initialPageSize={MINIMAL_INITIAL_PAGE_SIZE}
                header={() => <></>}
                hideFooter
                hideReloadButton
              />
            </RoundedWhiteBox>
            <CollectionSummaryDataGrid
              collectionManagersByPriority={collectionManagersByPriority}
            />
          </Stack>
        </Grid>
      </Grid>
      <ClientConfigDialog
        handleClose={setOpenClientConfig}
        open={openClientConfig}
        setOpen={setOpenClientConfig}
      />

    </>
  );
};

export default NewCollectionSummary;
